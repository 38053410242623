<template>
  <div class="footer">
    <div class="footer-box1">
      <div class="container">
        <div class="footer-img">
          <div class="footer-img-item">
            <img class="icon-img" src="@/assets/images/ft-icon1.svg" alt="" />
            <div class="icon-info">
              <div class="icon-title">平台保障</div>
              <div class="icon-desc">平台护航 科研无忧</div>
            </div>
          </div>
          <div class="footer-img-item">
            <img class="icon-img" src="@/assets/images/ft-icon2.svg" alt="" />
            <div class="icon-info">
              <div class="icon-title">专属客服</div>
              <div class="icon-desc">专业团队 贴心服务</div>
            </div>
          </div>
          <div class="footer-img-item">
            <img class="icon-img" src="@/assets/images/ft-icon3.svg" alt="" />
            <div class="icon-info">
              <div class="icon-title">产品丰富</div>
              <div class="icon-desc">品类齐全 轻松科研</div>
            </div>
          </div>
          <div class="footer-img-item">
            <img class="icon-img" src="@/assets/images/ft-icon4.svg" alt="" />
            <div class="icon-info">
              <div class="icon-title">质优价廉</div>
              <div class="icon-desc">全国调配 自有货源</div>
            </div>
          </div>
          <div class="footer-img-item">
            <img class="icon-img" src="@/assets/images/ft-icon5.svg" alt="" />
            <div class="icon-info">
              <div class="icon-title">专家学者</div>
              <div class="icon-desc">专家学者 科学匹配</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer-box2">
      <div class="container">
        <div class="footer-center">
          <div>
            <div class="footer-logo">
              <img src="@/assets/images/logo2.svg" alt="" />
            </div>
            <div class="footer-nav">
              <router-link class="footer-nav-item" to="/overview">科英会员</router-link>
              <router-link class="footer-nav-item" to="/keying/cooperate">合作入驻</router-link>
              <router-link class="footer-nav-item" to="/keying/news">科英资讯</router-link>
              <router-link class="footer-nav-item" to="/keying/recruit">科英招聘</router-link>
              <router-link class="footer-nav-item" to="/keying/about">关于我们</router-link>
            </div>
            <div class="copyright">
              <p>京公网安备 <a href="https://beian.miit.gov.cn" target="_blank" style="color: #aaa">京ICP备2023026895号</a> </p>
<!--              <p>增值电信业务经营许可证：浙B2-20210637</p>-->
              <p>Copyright © 2025 北京中科科英科技有限公司 版权所有</p>
            </div>
          </div>
          <div class="footer-contact">
            <div class="footer-contact-item">
              <div>
                <img src="@/assets/images/footer-icon-phone.svg" alt="" />
              </div>
              <div class="footer-contact-item-info">
                <h5>13641177680</h5>
                <p>客服热线（周一至周日9:00-24:00）</p>
              </div>
            </div>
            <div class="footer-contact-item">
              <div>
                <img src="@/assets/images/footer-icon-email.svg" alt="" />
              </div>
              <div class="footer-contact-item-info">
                <h5 style="font-size: 20px">yuxiujun@sci-coin.com</h5>
                <p>商务合作邮箱</p>
              </div>
            </div>
          </div>
          <div class="qrcode">
            <div class="qrcode-item">
              <img src="@/assets/images/qrcode2.jpg" alt="" />
              <p>关注我们 了解更多</p>
            </div>
            <div class="qrcode-item">
              <img src="@/assets/images/qrcode.jpg" alt="" />
              <p>随时预约 掌握进度</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="sidebar">
      <div class="left" v-if="!showSidebar">
        <div class="service">
          <img :src="require('@/assets/images/icon-kefu.svg')" />
          <span>在线客服</span>
        </div>
        <div class="toggleSidebar" @click="toggleSidebar">
          <div class="arrow">
            <i :class="{ 'el-icon-arrow-left': !showSidebar, 'el-icon-arrow-right': showSidebar }"></i>
          </div>
          <span>{{toggleText}}</span>
        </div>
      </div>
      <div class="right" v-if="showSidebar">
        <div class="service">
          <img :src="require('@/assets/images/icon-kefu.svg')" />
          <span>在线客服</span>
        </div>
        <div class="other">
          <router-link to="/shop/cart" class="sidebar-item">
            <em v-if="cartNum > 0">{{cartNum}}</em>
            <img :src="require('@/assets/images/icon-cart.svg')" />
            <span>购物车</span>
          </router-link>
          <div class="sidebar-item" @mouseenter="showGzh = true" @mouseleave="showGzh = false">
            <img :src="require('@/assets/images/icon-wechat.svg')" />
            <span>公众号</span>
            <div class="hover-img" v-if="showGzh">
              <img :src="require('@/assets/images/qrcode2.jpg')" style="width: 120px;height: 120px;"/>
            </div>
          </div>
          <div class="sidebar-item" @mouseenter="showMiniapp = true" @mouseleave="showMiniapp = false">
            <img :src="require('@/assets/images/icon-miniapp.svg')" />
            <span>小程序</span>
            <div class="hover-img" v-if="showMiniapp">
              <img :src="require('@/assets/images/qrcode.jpg')" style="width: 120px;height: 120px;"/>
            </div>
          </div>
          <div class="sidebar-item" style="border-top: 1px solid #ECECEC;padding-top: 10px;margin-bottom: 10px" @click="toggleSidebar">
            <div class="arrow">
              <i :class="{ 'el-icon-arrow-left': !showSidebar, 'el-icon-arrow-right': showSidebar }"></i>
            </div>
            <span>{{toggleText}}</span>
          </div>
        </div>
        <div class="goTop" v-if="scrollHeight > 200" @click="toTop">
          <div>
            <img :src="require('@/assets/images/icon-top.svg')" style="width: 40px;height: 40px;"/>
          </div>
          <div>回到顶部</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {getItemWithExpiry} from "@/utils/localsession";
import {apiCartList, apiCartNum} from "@/api/research_mall";
export default {
  name: "footerBar",
  data() {
    return {
			scrollHeight: 0,
      showSidebar: true,
      toggleText: '收起',
      cartNum: '',
      showGzh: false,
      showMiniapp: false
    }
  },
  mounted() {
  	window.addEventListener('scroll', () => {
      this.scrollHeight = document.documentElement.scrollTop || document.body.scrollTop;
    })
    this.$bus.$on("refreshShopCart", (value) => {
      this.getShopCartNum();
      return;
    })
    this.getShopCartNum();
  },
	beforeDestroy() {
  	window.removeEventListener('scroll', () => {})
  	this.$bus.$off("refreshShopCart");
	},
	methods: {
		toTop(){
			console.log('this.$parent',this.$parent);
			// this.$emit('toTop')
			// window.scrollTo(0,0);
			window.scrollTo({
				top: 0,
				behavior: 'smooth'
			});
		},
    toggleSidebar(){
      this.showSidebar = !this.showSidebar;
      this.toggleText = this.showSidebar ? '收起' : '展开';
    },
    getShopCartNum(){
      if(!getItemWithExpiry('coing_token')){
        return;
      }
			apiCartNum({}).then(async res => {
        this.cartNum = res.data || 0;
      }).catch(err => {})
    }
  }
}
</script>

<style lang="less" scoped>

.side-kefu{
  position: fixed;
  z-index: 99999;
  right: 0;
  bottom: calc(30% + 270px);
  box-shadow: -2px 0 30px 2px rgba(97, 105, 119, 0.18);
  border-radius: 18px 0 0 18px;
  width: 76px;
  background: #4E6EF0;
  padding: 10px 0 ;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  cursor: pointer;
  color: #fff;
  span{
    font-size: 13px;
    margin-top: 5px;
  }
}
.sidebar{
  position: fixed;
  z-index: 99999;
  right: 0;
  top: 50%;
  display: flex;
  align-items: center;
  .left{
    width: 50px;
    background: #FFFFFF;
    box-shadow: 0px 7px 30px 0px rgba(0,0,0,0.1);
    border-bottom-left-radius: 15px;
    border-top-left-radius: 15px;
    .service{
      background: #4E6EF0;
      color: #fff;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-size: 13px;
      border-top-left-radius: 15px;
      border-bottom-left-radius: 15px;
      padding-top: 15px;
      padding-bottom: 15px;
      span{
        writing-mode: vertical-lr;
        letter-spacing: 5px;
        margin-top: 5px;
      }
    }
    .toggleSidebar{
      padding: 10px 0;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      flex-direction: column;
      .arrow{
        width: 16px;
        height: 16px;
        border-radius: 16px;
        background: #4E6EF0;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        i{
          font-size: 10px;
        }
      }
      span{
        writing-mode: vertical-lr;
        letter-spacing: 5px;
        margin-top: 5px;
        font-size: 13px;
      }
    }
  }
  .right{
    width: 76px;
    .service{
      background: #4E6EF0;
      color: #fff;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-size: 13px;
      border-top-left-radius: 15px;
      border-bottom-left-radius: 15px;
      padding-top: 15px;
      padding-bottom: 15px;
      margin-bottom: 20px;
    }
    .other{
      background: #fff;
      box-shadow: -2px 0 30px 2px rgba(97, 105, 119, 0.18);
      border-radius: 15px 0 0 15px;
      width: 76px;
      padding: 15px 0 1px 0;
    }
    .goTop{
      background: #fff;
      box-shadow: -2px 0 30px 2px rgba(97, 105, 119, 0.18);
      border-radius: 15px 0 0 15px;
      width: 76px;
      height: 76px;
      margin-top: 30px;
      color: #333;
      font-size: 13px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      cursor: pointer;
    }
  }

  .sidebar-item{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 15px;
    position: relative;
    cursor: pointer;
    background: #fff;
    .arrow{
      width: 16px;
      height: 16px;
      border-radius: 16px;
      background: #4E6EF0;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      i{
        font-size: 10px;
      }
    }

    .hover-img{
      position: absolute;
      right: 86px;
      bottom: 0;
      padding: 10px;
      box-sizing: border-box;
      border-radius: 10px;
      background-color: #fff;
    }
    em{
      font-style: normal;
      font-size: 12px;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      right: 10px;
      top: 0;
      background: #f00;
      color: #fff;
    }
    span{
      font-size: 13px;
      margin-top: 5px;
    }
  }
}
.footer{
  background: rgba(0, 0, 0, 0.1);
  .footer-box1{
    background-color: #eee;
  }
  .footer-box2{
    background-color: #222;
    padding: 50px 0;
    color: #aaa;
  }
  .footer-center{
    display: flex;
    justify-content: space-between;
  }
  .footer-img{
    padding: 30px 0;
    display: flex;
    justify-content: space-between;
    .footer-img-item{
      display: flex;
      align-items: center;
      .icon-img{
        width: 52px;
        height: 52px;
      }
      .icon-info{
        margin-left: 10px;
        .icon-title{
          font-size: 16px;
          font-weight: 600;
        }
        .icon-desc{
          font-size: 13px;
          color: #888888;
        }
      }
    }
  }
  .footer-logo{
    margin-bottom: 30px;
  }
  .footer-contact{
    display: flex;
    flex-direction: column;
    color: #fff;
    margin-top: 85px;
    .footer-contact-item{
      margin-bottom: 40px;
      display: flex;
      .footer-contact-item-info{
        margin-left: 15px;
      }
      h5{
        font-size: 28px;
        font-weight: 600;
        margin: 0 0 5px 0;
      }
      p{
        font-size: 14px;
        margin: 0;
      }
    }
  }
  .footer-nav{
    display: flex;
    align-items: center;
    .footer-nav-item{
      color: #aaa;
      font-size: 13px;
      margin-right: 8px;
      display: flex;
      align-items: center;
      &:after{
        content: '';
        width: 1px;
        height: 10px;
        background: #aaa;
        margin-left: 8px;
      }
      &:last-child{
        margin-right: 0;
        &:after{
          display: none;
        }
      }
    }
  }
  .qrcode{
    display: flex;
    justify-content: flex-end;
    margin-top: 85px;
    .qrcode-item{
      margin-left: 50px;
      background-color: #fff;
      border-radius: 10px;
      padding: 10px;
      height: 150px;
      &:first-child{
        margin-left: 0;
      }
      img{
        width: 120px;
        height: 120px;
      }
      p{
        font-size: 12px;
        text-align: center;
        color: #000;
        margin: 5px 0 0 0;
      }
    }
  }
  .copyright{
    margin-top: 50px;
    font-size: 13px;
  }
}
</style>
